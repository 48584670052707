import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    chats: [],
    conversations: [],
    group: [],
  },
  getters: {
    getAllConversations(state) {
      return state.conversations;
    },
    getGroups(state) {
      return state.group;
    },
  },
  mutations: {
    setChats(state, data) {
      state.chats = data;
    },
    setConversation(state, data) {
      state.conversations = data;
    },
    addChat(state, data) {
      const item = state.chats.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.chats.push(data);
      }
    },
    editChat(state, data) {
      const item = state.chats.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    addConversation(state, data) {
      var isNew = true;
      state.conversations.map((q) => {
        if (q._id == data._id) {
          isNew = false;
        }
      });
      if (isNew) state.conversations.push(data);
    },
    updateConversation(state, data) {
      state.conversations = state.conversations.map((q) => {
        if (q._id !== data.conversation_id) return q;
        else {
          q.last_message = data.text;
          return q;
        }
      });
    },
    deleteConversation(state, data) {
      state.conversations = state.conversations.filter(
        (q) => q._id !== data._id
      );
    },
    addUserToGroup(state, data) {
      state.group.push(data);
    },
  },
  actions: {
    fetchAllConversations({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        ApiManager.getAllConversations(
          {
            Authorization: rootState.auth.token,
          },
          (res) => {
            commit("setConversation", res);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getPartnerChats({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/partner-client", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendFilteredMessage({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/send-filtered-message", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getChat({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        ApiManager.getMessages(
          {
            Authorization: rootState.auth.token,
          },
          { _id },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getConversation({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        ApiManager.getConversation(
          {
            Authorization: rootState.auth.token,
          },
          { _id },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    sendMessage({ rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.sendMessage(
          {
            Authorization: rootState.auth.token,
          },
          params,
          () => {
            resolve();
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
    editChat({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.editChat(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (chat) => {
            commit("editChat", chat);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    updateConversation({ commit }, params) {
      commit("updateConversation", params);
    },
    addUserToGroup({ commit }, params) {
      commit("addUserToGroup", params);
    },
    deleteConversation({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.deleteConversation(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (conversation) => {
            commit("deleteConversation", conversation);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    createNewConversation({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/create-new-conversation", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addConversation", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
