import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    promoCodes: [],
  },
  getters: {
    getAllPromoCodes(state) {
      return state.promoCodes;
    },
  },
  mutations: {
    setPromoCodes(state, data) {
      state.promoCodes = data;
    },
    addPromoCode(state, data) {
      const item = state.promoCodes.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.promoCodes.push(data);
      }
    },
    editPromoCode(state, data) {
      if (state.promoCodes.length == 0) {
        state.promoCodes.push(data);
      }
      const item = state.promoCodes.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deletePromoCode(state, data) {
      state.promoCodes = state.promoCodes.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllPromoCodes({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("promo-code/all", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setPromoCodes", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getPromoCode({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("promo-code/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addPromoCode({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("promo-code/add", param)
          .then((res) => {
            if (res.data.success == true) {
              // commit("addPromoCode", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editPromoCode({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("promo-code/update", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editPromoCode", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deletePromoCode({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("promo-code/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deletePromoCode", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
