import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import Highcharts3d from "highcharts/highcharts-3d";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import * as VueGoogleMaps from "vue2-google-maps";
import JsonExcel from "vue-json-excel";
import VueBarcode from "@chenfengyuan/vue-barcode";
import Vue2Editor from "vue2-editor";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

Vue.use(
  VuetifyGoogleAutocomplete
  //   {
  //   apiKey: "AIzaSyAxnwgHYbXKBSCSFe_sFWfIY8F-LPcSJgQ",
  // }
);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAxnwgHYbXKBSCSFe_sFWfIY8F-LPcSJgQ",
    libraries: "places",
  },
  autobindAllEvents: true,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
});
Vue.config.productionTip = false;
Highcharts3d(Highcharts);
Vue.use(HighchartsVue);
Vue.use(Vue2Editor);
Vue.component("downloadExcel", JsonExcel);
Vue.component(VueBarcode.name, VueBarcode);
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.filter("highlight", function(words, query) {
  try {
    query = query.replaceAll("(", "\\(");
    query = query.replaceAll(")", "\\)");
    var iQuery = new RegExp(query, "ig");
    return words.toString().replace(iQuery, function(matchedTxt) {
      return "<span class='highlight'>" + matchedTxt + "</span>";
    });
  } catch (e) {
    console.log(e);
  }
  return words;
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
