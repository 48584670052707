import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    filters: [],
  },
  getters: {
    getAllFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    setFilters(state, data) {
      state.filters = data;
    },
    addFilter(state, data) {
      const item = state.filters.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.filters.push(data);
      }
    },
    editFilter(state, data) {
      if (state.filters.length == 0) {
        state.filters.push(data);
      }
      const item = state.filters.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteFilter(state, data) {
      state.filters = state.filters.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllFilters({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("filters/all", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setFilters", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFilter({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("filters/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addFilter({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("filters/add", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addFilter", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editFilter({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("filters/update", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editFilter", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteFilter({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("filters/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteFilter", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
