import axios from "axios";

/**
 * Create Axios Instance
 */
const createInstance = (token = "") => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      ...(token ? { Authorization: token } : {}),
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    // ----------------------------- API Call Start ----------------------------- /
    / eslint-disable no-console /;
    // console.log("[===== Started API Call =====]");
    return config;
  });

  axiosInstance.interceptors.response.use((response) => {
    // ------------------------------ API Call End ------------------------------ /
    // console.log("[===== Ended API Call =====]");
    return response;
  });

  return axiosInstance;
};

export default createInstance;
