<template>
  <v-app>
    <navigation v-if="showMenu"></navigation>
    <v-main
      class="main-container"
      :style="showMenu ? 'padding-top: 70px;' : 'padding-tp: 0'"
    >
      <router-view />
    </v-main>
    <GmapMap
      :center="center"
      :zoom="13"
      map-type-id="terrain"
      style="width: 200px; height: 150px;"
      v-show="false"
    >
      <GmapMarker :position="center" />
    </GmapMap>
  </v-app>
</template>

<script>
import Navigation from "./components/core/Navigation.vue";

export default {
  name: "App",
  components: {
    Navigation,
  },
  data() {
    return {
      showMenu: false,
      center: { lat: 42.278767, lng: -71.420351 },
    };
  },
  watch: {
    $route(to) {
      if (
        to.name === "login" ||
        to.name == "landing" ||
        to.name == "register" ||
        to.name == "forgotpassword" ||
        to.name == "verifycode" ||
        to.name == "resetpassword"
      ) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
  },
  mounted() {
    if (
      this.$route.name === "login" ||
      this.$route.name == "landing" ||
      this.$route.name == "forgotpassword" ||
      this.$route.name == "verifycode" ||
      this.$route.name == "resetpassword"
    ) {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}
.v-application--wrap {
  height: 100%;
}
html {
  height: 100%;
  overflow-y: auto !important;
}
body {
  height: 100%;
  background: #f4f9ff;
}
.main-container {
  height: 100%;
  background: #f4f9ff;
}
#app {
  height: 100%;
}

.w-100 {
  width: 100%;
}
.primary-color {
  color: #7024c4;
}
.highlight {
  background-color: yellow;
}
.app-bold-font {
  font-family: "Poppins-Bold";
}
.app-medium-font {
  font-family: "Poppins-Medium";
}

.font-12 {
  font-size: 12px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-20 {
  font-size: 20px !important;
}
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.text-block {
  white-space: pre-line;
  overflow: hidden;
  display: block;
}
.flex-grow {
  flex-grow: 1;
}
.hover-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.hover-item:hover {
  background-color: #eef3f8;
}
.url-button {
  cursor: pointer;
}
.url-button:hover {
  text-decoration: underline;
  color: #0a66c2;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.justify-between {
  justify-content: space-between;
}
.wrap-word {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
}
</style>
