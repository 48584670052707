import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    getAllNotifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    setNotifications(state, data) {
      state.notifications = data;
    },
    addNotification(state, data) {
      const item = state.notifications.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.notifications.push(data);
      }
    },
    editNotification(state, data) {
      if (state.notifications.length == 0) {
        state.notifications.push(data);
      }
      const item = state.notifications.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteNotification(state, data) {
      state.notifications = state.notifications.filter(
        (q) => q._id !== data._id
      );
    },
  },
  actions: {
    fetchAllNotifications({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notifications/all", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setNotifications", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAvailableNotifications({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notifications/availables", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setNotifications", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    markAsRead({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notifications/mark-as-read", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editNotification", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getNotification({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notifications/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addNotification({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notifications/add", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addNotification", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editNotification({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notifications/update", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editNotification", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteNotification({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notifications/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteNotification", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
