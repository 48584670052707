import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("user-token") || "",
    profile: {},
    keyword: "",
    type: localStorage.getItem("type") || "",
  },
  getters: {
    getToken(state) {
      return state.token;
    },

    isAuthenticated: (state) => !!state.token,

    getProfile(state) {
      return state.profile;
    },
    getType(state) {
      return state.type;
    },
    getKeyword(state) {
      return state.keyword;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setType(state, type) {
      state.type = type;
    },
    setProfile(state, data) {
      state.profile = data;
    },
    updateProfile(state, data) {
      state.profile = data;
    },
    setKeyword(state, data) {
      state.keyword = data;
    },
  },
  actions: {
    onLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/web-login", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setToken", res.data.token);
              commit("setType", res.data.type);
              if (params.rememberme) {
                localStorage.setItem("user-token", res.data.token);
                localStorage.setItem("type", res.data.type);
              }
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onRegister({ state }, params) {
      console.log(state.token);
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/user-register", params)
          .then((res) => {
            if (res.data.success == true) {
              // commit("setToken", res.data.token);
              // commit("setType", res.data.type);
              // if (params.rememberme) {
              //   localStorage.setItem("user-token", res.data.token);
              //   localStorage.setItem("type", res.data.type);
              // }
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onForgotPassword({ state }, params) {
      console.log(state.token);
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/forgot-password", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onSubmitVerificationCode({ state }, params) {
      console.log(state.token);
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/verify-otp", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onResetPassword({ state }, params) {
      console.log(state.token);
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/reset-password", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onLogout({ commit }) {
      commit("setToken", null);
      commit("setType", null);
      localStorage.removeItem("user-token");
      localStorage.removeItem("type");
    },
    fetchProfile({ commit, state }) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          ApiManager.fetchAdmin(
            {
              Authorization: state.token,
            },
            (res) => {
              commit("setProfile", res);
              resolve(res);
            },
            (error) => {
              if (error.response.status == 401) {
                commit("setToken", null);
                commit("setType", null);
                localStorage.removeItem("user-token");
                localStorage.removeItem("type");
              }
              reject(error);
            }
          );
        });
      } else if (state.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("partners/profile")
            .then((res) => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (state.type == "Freezer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("freezers/profile")
            .then((res) => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (state.type == "User") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/profile")
            .then((res) => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateAdmin({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.updateAdmin(
          {
            Authorization: state.token,
          },
          params,
          (res) => {
            commit("updateProfile", res);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    updatePartner({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(state.token)
          .post("partners/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setProfile", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePassword({ state }, params) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          ApiManager.updatePassword(
            {
              Authorization: state.token,
            },
            params,
            () => {
              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        });
      } else if (state.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("partners/password", params)
            .then((res) => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getDistributionList({ state }, params) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          ApiManager.getDistributionList(
            {
              Authorization: state.token,
            },
            params,
            (res) => {
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } else if (state.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("distribution/partner-home")
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getTotalDistributionList({ state }, params) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("intakes/home", params)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    fetchHomeInfo({ state }) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          ApiManager.fetchHomeInfo(
            {
              Authorization: state.token,
            },
            (res) => {
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } else if (state.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("partners/home")
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    clearKeyword({ commit }) {
      commit("setKeyword", "");
    },
    confirmPassword({ state }, params) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/confirm-password", params)
            .then((res) => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        return new Promise((reject) => {
          console.log("here");
          reject("No permission");
        });
      }
    },
    fetchAllAdmin({ state }) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/all")
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    addAdminAccount({ state }, param) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/add-admin", param)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateAdminAccount({ state }, param) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/update-admin", param)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    deleteAdminAccount({ state }, param) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/delete-admin", param)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    verifyEmail({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("auth/verify-email", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.message);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
