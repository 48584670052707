import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    settings: [],
    categories: [],
    homeImages: [],
  },
  getters: {
    getAllSettings(state) {
      return state.settings;
    },
    getAllCategories(state) {
      return state.categories;
    },
    getAllHomeImages(state) {
      return state.homeImages;
    },
  },
  mutations: {
    setSettings(state, data) {
      state.settings = data;
    },
    setCategories(state, data) {
      state.categories = data;
    },
    setHomeImages(state, data) {
      state.homeImages = data;
    },
    addSetting(state, data) {
      const item = state.settings.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.settings.push(data);
      }
    },
    editSetting(state, data) {
      const item = state.settings.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteSetting(state, data) {
      state.settings = state.settings.filter((q) => q._id !== data._id);
    },
    addCategory(state, data) {
      const item = state.categories.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.categories.push(data);
      }
    },
    editCategory(state, data) {
      const item = state.categories.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteCategory(state, data) {
      state.categories = state.categories.filter((q) => q._id !== data._id);
    },
    addHomeImage(state, data) {
      const item = state.homeImages.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.homeImages.push(data);
      }
    },
    editHomeImage(state, data) {
      const item = state.homeImages.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteHomeImage(state, data) {
      state.homeImages = state.homeImages.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllSettings({ commit, rootState }) {
      if (rootState.auth.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("settings/list")
            .then((res) => {
              if (res.data.success == true) {
                commit("setSettings", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (rootState.auth.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("partner-settings/list")
            .then((res) => {
              if (res.data.success == true) {
                commit("setSettings", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    fetchAllCategories({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("categories/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setCategories", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAllHomeImages({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("home-images/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setHomeImages", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getValues({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/values", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addSetting({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addSetting", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editSetting({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editSetting", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteSetting({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteSetting", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addValue({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/add-value", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editValue({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/update-value", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteValue({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/delete-value", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addCategory({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("categories/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addCategory", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editCategory({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("categories/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editCategory", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteCategory({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("categories/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteCategory", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addHomeImage({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("home-images/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addHomeImage", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editHomeImage({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("home-images/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editHomeImage", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteHomeImage({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("home-images/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteHomeImage", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    ///Parter settings
    getPartnerValues({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partner-settings/values", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addPartnerSetting({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partner-settings/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addSetting", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editPartnerSetting({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partner-settings/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editSetting", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deletePartnerSetting({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partner-settings/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteSetting", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addPartnerValue({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partner-settings/add-value", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editPartnerValue({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partner-settings/update-value", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deletePartnerValue({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partner-settings/delete-value", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
