import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    requests: [],
  },
  getters: {
    getAllRequests(state) {
      return state.requests;
    },
  },
  mutations: {
    setRequests(state, data) {
      state.requests = data;
    },
    addRequest(state, data) {
      const item = state.requests.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.requests.push(data);
      }
    },
    editRequest(state, data) {
      const item = state.requests.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteRequest(state, data) {
      state.requests = state.requests.filter((q) => q._id !== data._id);
    },
    setStreets(state, data) {
      state.streets = data;
    },
    setStreetList(state, data) {
      state.streetList = data;
    },
  },
  actions: {
    fetchAllRequests({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        ApiManager.getAllRequests(
          {
            Authorization: rootState.auth.token,
          },
          (res) => {
            commit("setRequests", res);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getRequest({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("requests/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addRequest({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addRequest(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (request) => {
            commit("addRequest", request);
            resolve();
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
    editRequest({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.editRequest(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (request) => {
            commit("editRequest", request);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteRequest({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.deleteRequest(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (request) => {
            commit("deleteRequest", request);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    setBalance({ rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.setBalance(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
  },
};
