import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    checkedIns: [],
  },
  getters: {
    getAllCheckedIns(state) {
      return state.checkedIns;
    },
  },
  mutations: {
    setCheckedIns(state, data) {
      state.checkedIns = data;
    },
    addCheckedIn(state, data) {
      const item = state.checkedIns.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.checkedIns.push(data);
      }
    },
    editCheckedIn(state, data) {
      const item = state.checkedIns.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteCheckedIn(state, data) {
      state.checkedIns = state.checkedIns.filter((q) => q._id !== data._id);
    },
    setStreets(state, data) {
      state.streets = data;
    },
    setStreetList(state, data) {
      state.streetList = data;
    },
  },
  actions: {
    fetchAllCheckedIns({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("checked-in/all", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setCheckedIns", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    oldCheckedInData({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("checked-in/old-data", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setCheckedIns", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCheckedIn({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("checked-in/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addCheckedIn({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("checked-in/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addCheckedIn", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editCheckedIn({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("checked-in/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editCheckedIn", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteCheckedIn({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("checked-in/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteCheckedIn", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
