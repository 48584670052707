var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"z-index":"12"},attrs:{"color":"#7024c4","max-height":"70","height":"70","app":"","fixed":""}},[_c('div',[_c('v-img',{staticClass:"ml-3 hidden-xs-only",attrs:{"src":require("@/assets/white_title_logo.svg"),"height":"30","width":"240","contain":""},on:{"click":_vm.onBackHome}}),_c('v-img',{staticClass:"ml-3 hidden-sm-and-up",attrs:{"src":require("@/assets/white_logo.svg"),"height":"30","width":"30","contain":""},on:{"click":_vm.onBackHome}})],1),_c('div',[(_vm.isAuthenticated && _vm.isSearch)?_c('v-text-field',{staticClass:"ml-10",attrs:{"hide-details":"","label":"Search","solo":"","dense":"","rounded":"","outlined":"","append-icon":"mdi-magnify"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearchEnter($event)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}):_vm._e()],1),_c('v-spacer'),(_vm.isAuthenticated)?_c('div',{staticClass:"mr-3"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.notifications.filter(function (n) { return !n.read; }).length,"overlap":"","content":_vm.notifications.filter(function (n) { return !n.read; }).length}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-bell")])],1)],1)]}}],null,false,2878853821),model:{value:(_vm.notificationMenu),callback:function ($$v) {_vm.notificationMenu=$$v},expression:"notificationMenu"}},[_c('div',{staticStyle:{"background-color":"white"}},[_c('v-list',{staticStyle:{"max-height":"80vh","overflow":"auto"}},[_c('v-list-item',{staticClass:"app-bold-font font-20"},[_vm._v(" Notification ")]),(_vm.notifications.length)?_c('div',[_c('v-list-item',[_c('v-btn',{attrs:{"rounded":"","depressed":""}},[_vm._v("All")]),_c('v-btn',{staticClass:"ml-5",attrs:{"rounded":"","depressed":""}},[_vm._v("Unread")])],1),_vm._l((_vm.notifications),function(item,index){return _c('v-list-item',{key:index,staticClass:"hover-item pb-2 pt-2",on:{"click":function($event){return _vm.onSelectedNotification(item)}}},[_c('v-list-item-title',{staticClass:"d-flex align-center w-100"},[_c('div',{staticClass:"mr-2"},[_c('v-img',{attrs:{"src":item.image,"width":"40","height":"40"}})],1),_c('div',{staticClass:"wrap-word",staticStyle:{"width":"calc(100% - 100px)"}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('div',{staticClass:"mr-1"},[_c('v-menu',{attrs:{"offset-y":"","rounded":"lg","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.notificationItemMenus),function(menuItem,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"change":function($event){return _vm.onSelectedNotificationMenu(menuItem, item)}}},[_c('v-list-item-title',{staticClass:"mr-3"},[_c('v-icon',{staticClass:"mr-3",domProps:{"textContent":_vm._s(menuItem.icon)}}),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)],1)}),1)],1)],1),(!item.read)?_c('div',{staticStyle:{"width":"10px","height":"10px","background-color":"blueviolet","border-radius":"5px"}}):_vm._e()])],1)})],2):_c('div',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("No notifications")])],1)],1)],1)],1)])],1):_vm._e(),(_vm.isAuthenticated)?_c('div',{staticClass:"hidden-xs-only"},[(_vm.profile.city)?_c('label',{staticClass:"name noselect ml-4"},[_vm._v(_vm._s((_vm.profile.city ? _vm.profile.city : "") + ", " + (_vm.profile.state ? _vm.profile.state : "")))]):_vm._e()]):_vm._e(),(_vm.isAuthenticated)?_c('v-avatar',{staticClass:"elevation-6 ml-5",attrs:{"size":"40"},on:{"click":_vm.goToProfile}},[_c('v-img',{attrs:{"src":_vm.profile.image ? _vm.profile.image : _vm.placeholderImage}})],1):_vm._e(),(_vm.isAuthenticated)?_c('div',{staticClass:"hidden-sm-and-down",on:{"click":_vm.goToProfile}},[_c('label',{staticClass:"name noselect ml-4"},[_vm._v(_vm._s((_vm.profile.first_name ? _vm.profile.first_name : "") + " " + (_vm.profile.last_name ? _vm.profile.last_name : "")))])]):_vm._e(),(_vm.isAuthenticated)?_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1554117151)},[_c('v-list',_vm._l((_vm.menus),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"change":function($event){return _vm.onSelectedMenu(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }