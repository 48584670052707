import axios from "axios";

export default {
  post(url, headers, params, onSuccess, onError) {
    axios
      .post(url, params, { headers })
      .then((res) => {
        if (res.data.success == true) {
          if (res.data.token) {
            onSuccess(res.data.token);
          } else {
            onSuccess(res.data.res);
          }
        } else {
          onError(res.data.message);
        }
      })
      .catch((error) => {
        onError(error);
      });
  },

  onLogin(params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "auth/admin-login",
      null,
      params,
      onSuccess,
      onError
    );
  },

  //Client API
  getAllClients(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "clients/all",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  addClient(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "clients/add",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  getClient(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "clients/get",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  editClient(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "clients/update",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  deleteClient(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "clients/delete",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  setBalance(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "balances/set",
      headers,
      params,
      onSuccess,
      onError
    );
  },

  //Admin update
  fetchAdmin(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "admin/profile",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  updateAdmin(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "admin/update",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  updatePassword(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "admin/password",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  fetchHomeInfo(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "admin/home",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  //Question API
  getAllQuestions(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "questions/all",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  addQuestion(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "questions/add",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  editQuestion(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "questions/update",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  deleteQuestion(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "questions/delete",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  //Intake API
  fetchAllIntakes(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "intakes/all",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  saveIntake(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "intakes/set",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  //Partner API
  getAllPartners(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "partners/all",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  getPartnerProfile(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "partners/get",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  addPartner(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "partners/add",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  editPartner(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "partners/update",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  deletePartner(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "partners/delete",
      headers,
      params,
      onSuccess,
      onError
    );
  },

  //Request API
  getAllRequests(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "requests/all",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  deleteRequest(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "requests/delete",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  //Referral API
  getAllReferrals(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "referrals/all",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  addReferral(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "referrals/create",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  editReferral(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "referrals/update",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  deleteReferral(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "referrals/delete",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  //Chat API
  getAllConversations(headers, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "chat/all",
      headers,
      null,
      onSuccess,
      onError
    );
  },
  getConversation(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "chat/conversation",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  getMessages(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "chat/messages",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  sendMessage(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "chat/send-message",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  deleteConversation(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "chat/delete-conversation",
      headers,
      params,
      onSuccess,
      onError
    );
  },
  // Get Distribution Data
  getDistributionList(headers, params, onSuccess, onError) {
    this.post(
      process.env.VUE_APP_API_URL + "distribution/home",
      headers,
      params,
      onSuccess,
      onError
    );
  },
};
